.react-multi-carousel-track {
    height: 210px;
}
.react-multi-carousel-track li{
    margin-left: 10px;
}
.react-multi-carousel-track li:first-child{
    margin-left: 0px !important;
}

select {
    margin: 50px;
    width: 300px;
    background: #fff;
    color: #000;
  }
  
option:not(:checked) {
background-color: #fff;
}